<template>
  <q-form ref="editForm">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-3">
        <c-card title="점검설비 상세" class="cardClassDetailForm">
          <template slot="card-button">
            <q-btn-group outline >
              <!-- <c-btn 
                v-show="editInfo&&preStartupCheck.sopPrestartupCheckId&&!disabled" 
                :isSubmit="isComplete"
                :url="completeUrl"
                :param="preStartupEquipment"
                mappingType="PUT"
                label="완료" 
                icon="check"
                @beforeAction="completePreStartupEquipment"
                @btnCallback="completeCallback" /> -->
            </q-btn-group>
          </template>
          <template slot="card-detail">
            <div class="col-6">
              <c-text
                :editable="editInfo"
                :disabled="true"
                label="설비유형"
                name="equipmentTypeName"
                v-model="preStartupEquipment.equipmentTypeName">
              </c-text>
            </div>
            <div class="col-6">
              <c-field 
                :required="true" 
                :editable="editInfo"
                :disabled="disabled"
                type="user" 
                label="설비점검책임자" 
                name="checkUserId" 
                v-model="preStartupEquipment.checkUserId" />
            </div>
            <div class="col-12">
              <c-multi-select
                :editable="editInfo"
                :disabled="disabled"
                :isObject="true"
                :disabledItems="disabledItems"
                codeGroupCd="PSR_CHECK_TYPE_CD"
                itemText="codeName"
                itemValue="code"
                valueText="psrCheckTypeName"
                valueKey="psrCheckTypeCd"
                label="점검유형"
                name="checkTypes"
                v-model="preStartupEquipment.checkTypes"
                @datachange="changeCheckType">
              </c-multi-select>
            </div>
            <div class="col-6">
              <c-datepicker
                :editable="editInfo"
                :disabled="disabled"
                label="점검예정일"
                name="checkScheduleDate"
                v-model="preStartupEquipment.checkScheduleDate"
              />
            </div>
            <div class="col-6">
              <c-datepicker
                :editable="editInfo"
                :disabled="disabled"
                label="점검일"
                name="checkDate"
                v-model="preStartupEquipment.checkDate"
              />
            </div>
            <div class="col-12">
              <c-textarea
                :editable="editInfo"
                :disabled="disabled"
                :rows="5"
                label="점검결과종합"
                name="checkResultTotal"
                v-model="preStartupEquipment.checkResultTotal">
              </c-textarea>
            </div>
          </template>
        </c-card>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-9">
        <c-table
          ref="table"
          title="점검결과 목록"
          :columns="grid.columns"
          :gridHeight="grid.height"
          :data="preStartupEquipment.checkItems"
          :isTitle="true"
          :merge="grid.merge"
          :filtering="false"
          :columnSetting="false"
          :usePaging="true"
          :editable="editInfo&&!disabled"
          :isTop="false"
        >
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name==='customCol'">
              <component
                :is="imprComponent"
                :col="col"
                :props="props"
                :inputEditable="editable&&!disabled"
                :isImmShow="true"
                :requestContentsCols="requestContentsCols"
                tableKey="sopPrestartupEquipmentHasCheckItemId"
                ibmTaskTypeCd="ITT0000030"
                ibmTaskUnderTypeCd="ITTU000050"
                :acceptBtnShow="true"
                @imprChange="imprChange"
              >
                <template v-slot:customArea="{ props }">
                  <div class="col-6">
                    <div class="mini-dailog-title">점검결과</div>
                  </div>
                  <div class="col-12">
                    <c-text
                      :editable="editInfo&&!disabled"
                      label=""
                      name="checkResult"
                      v-model="props.row.checkResult">
                    </c-text>
                  </div>
                </template>
              </component>
            </template>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </q-form>
</template>

<script>
// import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'pre-startup-facility',
  props: {
    param: {
      type: Object,
      default: function() {
        return {
          sopPrestartupCheckId: '',
          isSearch: false,
        }
      }
    },
    preStartupCheck: {
      type: Object,
      default: function() {
        return {
          plantCd: null,
          sopPrestartupCheckId: '',
          equipments: [],
        }
      }
    },
    equipmentCd: {
      type: String,
      default: function() {
        return ''
      }
    },
    research: {
      type: Object,
      default: () => ({
        impr: '',
        item: '',
      }),
    },
  },
  data() {
    return {
      preStartupEquipment: {
        sopPrestartupCheckId: '',  // 가동전점검 일련 번호
        equipmentCd: '',  // 설비코드
        equipmentName: '',  // 설비명
        equipmentTypeCd: '',  // 설비 분류(상하위구조)
        equipmentTypeName: '',  // 설비 분류명(상하위구조)
        checkUserId: '',  // 점검 책임자
        checkScheduleDate: '',  // 점검 예정일
        checkDate: '',  // 점검일
        checkResultTotal: '',  // 점검 결과 종합
        checkCompleteFlag: 'N',  // 점검 완료 여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        checkTypes: [], // 점검유형
        checkItems: [], // 점검항목
        deleteCheckItems: [],
      },
      grid: {
        merge: [
          { index: 0, colName: 'level1' },
          { index: 1, colName: 'level2' },
          // { index: 0, colName: 'psrCheckTypeName' },
          // { index: 1, colName: 'level1' },
          // { index: 2, colName: 'level2' },
        ],
        columns: [],
        data: [],
        height: '600px'
      },
      requestImprRow: null,
      editable: true,
      listUrl: '',
      completeUrl: '',
      isComplete: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
    };
  },
  computed: {
    editInfo() {
      return this.editable && !this.param.isSearch
    },
    disabled() {
      // 점검완료인 경우 비활성화
      return this.preStartupEquipment.checkCompleteFlag === 'Y' || this.preStartupCheck.psrCheckStatusCd === 'PCSC000005';
    },
    disabledItems() {
      let result = [];
      let disabledSteps = ['IS00000005', 'IS00000010', 'IS00000015', 'IS00000020']
      if (this.preStartupEquipment 
        && this.preStartupEquipment.checkItems 
        && this.preStartupEquipment.checkItems.length > 0) {
        this.$_.forEach(this.preStartupEquipment.checkItems, checkItem => {
          if (checkItem.improves && checkItem.improves.length > 0) {
            this.$_.forEach(checkItem.improves, improve => {
              if (this.$_.indexOf(disabledSteps, improve.ibmStepCd) > -1) {
                result.push(checkItem.psrCheckTypeCd);
                return false;
              }
            })
          }
        })
      }
      return result;
    },
    checkTypes() {
      return this.$_.map(this.preStartupEquipment.checkTypes, 'psrCheckTypeCd')
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['level1', 'level2'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  watch: {
    'preStartupCheck': {
      handler: function () {
        this.preStartupEquipment = this.$_.find(this.preStartupCheck.equipments, { equipmentCd: this.equipmentCd });
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
      },
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.psr.check.checkTypeItemList.url
      this.completeUrl = transactionConfig.sop.psr.check.equipment.complete.url
      // code setting
      this.grid.columns = [
        {
          name: 'checkItemName',
          field: 'checkItemName',
          label: '점검항목',
          sortable: false,
          child: [
            {
              name: 'level1',
              field: 'level1',
              label: '대분류',
              align: 'left',
              sortable: false,
            },
            {
              name: 'level2',
              field: 'level2',
              label: '중분류',
              align: 'left',
              sortable: false,
            },
          ]
        },
        {
          name: 'judgmentResultFlag',
          field: 'judgmentResultFlag',
          label: '적합<br>여부',
          align: 'center',
          sortable: false,
          type: 'select',
          style: 'width:55px',
          comboItems: [
            { code: 'Y', codeName: 'O' },
            { code: 'N', codeName: 'X' },
            { code: 'D', codeName: 'N/A' },
          ],
          setHeader: true,
        },
        {
          name: 'customCol',
          field: 'customCol',
          label: '',
          align: 'center',
          type: 'custom',
          style: 'width:30px',
          sortable: false
        },
      ];
      // list setting
      this.preStartupEquipment = this.$_.find(this.preStartupCheck.equipments, { equipmentCd: this.equipmentCd });
    },
    changeCheckType(val) {
      if (!val || val.length === 0) {
        this.$_.forEach(this.preStartupEquipment.checkItems, deleteItem => {
          if (this.$_.findIndex(this.preStartupEquipment.deleteCheckItems, { sopPrestartupCheckItemId: deleteItem.sopPrestartupCheckItemId }) === -1
            && deleteItem.editFlag !== 'C') {
            this.preStartupEquipment.deleteCheckItems.push(deleteItem)
          }
        })
        this.preStartupEquipment.checkItems = [];
      } else {
        this.$http.url = this.listUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.preStartupCheck.plantCd,
          checkTypes: this.$_.map(val, 'value')
        }
        this.$http.request((_result) => {
          if (_result.data && _result.data.length > 0) {
            if (this.preStartupEquipment.checkItems && this.preStartupEquipment.checkItems.length > 0) {
              this.$_.forEach(_result.data, item => {
                if (this.$_.findIndex(this.preStartupEquipment.checkItems, { sopPrestartupCheckItemId: item.sopPrestartupCheckItemId }) === -1) {
                  item.editFlag = 'C'
                  this.preStartupEquipment.checkItems.push(item);
                }
              })

              let deleteItems = [];
              this.$_.forEach(this.preStartupEquipment.checkItems, item => {
                if (this.$_.findIndex(_result.data, { sopPrestartupCheckItemId: item.sopPrestartupCheckItemId }) === -1) {
                  deleteItems.push(item);
                }
              })
              if (deleteItems && deleteItems.length > 0) {
                if (!this.preStartupEquipment.deleteCheckItems) this.preStartupEquipment.deleteCheckItems = [];
                this.$_.forEach(deleteItems, deleteItem => {
                  this.preStartupEquipment.checkItems = this.$_.reject(this.preStartupEquipment.checkItems, { sopPrestartupCheckItemId: deleteItem.sopPrestartupCheckItemId })

                  if (this.$_.findIndex(this.preStartupEquipment.deleteCheckItems, { sopPrestartupCheckItemId: deleteItem.sopPrestartupCheckItemId }) === -1
                    && deleteItem.editFlag !== 'C') {
                    this.preStartupEquipment.deleteCheckItems.push(deleteItem)
                  }
                })
              }
            } else {
              this.$_.forEach(_result.data, item => {
                item.editFlag = 'C'
              })
              this.preStartupEquipment.checkItems = _result.data;
            }
          } else {
            this.preStartupEquipment.checkItems = [];
          }
        },);
      }
    },
    /* eslint-disable no-unused-vars */
    completePreStartupEquipment() {
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '완료하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.preStartupEquipment.chgUserId = this.$store.getters.user.userId
              this.isComplete = !this.isComplete
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    completeCallback(result) {
      this.preStartupEquipment.checkCompleteFlag = 'Y'
      window.getApp.$emit('APP_REQUEST_SUCCESS');
    },
    // 개선창 닫기 후
    imprChange() {
      // this.$set(this.research, 'impr', uid())
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>